<template>
  <div>
    <asset-header-card
      :asset="asset"
      :training-data="true"
    />
    <b-card
      style="width: 100%; height: 70vh;"
      class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
    >
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <b-input-group size="md">
            <b-form-select
              v-model="modelName"
              :options="[{ value: 'CNN', text: 'CNN' }, { value: 'TFModel', text: 'TFModel' }]"
              variant="primary"
            />
            <b-form-input
              id="lag"
              v-model="lag"
              type="number"
              variant="primary"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="isBusy"
                class="mr-1 ml-1"
                @click="submitTraining"
              >
                <b-spinner
                  v-if="isBusy"
                  small
                  type="grow"
                />
                {{ trainingButton }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <date-picker
          v-model="firstDay"
          type="month"
          placeholder="Select month"
          @close="queryThisMonth"
        />
        <b-form-checkbox
          v-model="quickDraw"
          class="ml-1"
          name="check-button"
          switch
          @change="option.series[0].large=!quickDraw"
        >
          <b>{{ quickDraw ? 'Disable':'Enable' }} Magnitude Color Coding</b>
        </b-form-checkbox>
      </div>
      <b-overlay
        :show="!option"
        rounded="sm"
      >
        <app-echart-scatter
          :key="`${firstDay.getTime()}_${lastDay.getTime()}_${quickDraw}`"
          :style-obj="{width: '100%', height: '30vh'}"
          :option-data="option"
        />
      </b-overlay>
      <div>
        <b-table
          :id="tableID"
          responsive="sm"
          sticky-header="35vh"
          show-empty
          :fields="fields"
          :items="items"
          primary-key="id"
          :busy="isBusy"
          sort-by="updatedAt"
          :sort-desc="true"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner
                class="align-middle"
                variant="primary"
              />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #row-details="row">
            <app-echart-scatter
              v-if="selectedOption"
              :key="`${row.item.windowStart}_${row.item.windowEnd}`"
              :style-obj="{width: '100%', height: '30vh'}"
              :option-data="selectedOption"
              @brushEnd="brushEnd"
            />
          </template>
          <template #cell(approved)="row">
            <span v-if="row.item.approved"> {{ row.item.approvedBy }}</span>
          </template>
          <template #cell(action)="row">
            <BIconTrash
              class="trash text-danger mr-1"
              font-scale="1.5"
              @click="handleDelete(row.item)"
            />
            <BIconCheck
              v-if="row.item.edit"
              class="trash text-info mr-1"
              font-scale="2"
              @click="handleSave(row.item)"
            />
            <BIconPencilSquare
              v-else
              class="trash text-success mr-1"
              font-scale="1.5"
              @click="selectItem(row)"
            />
            <BIconCheck2Circle
              v-if="!row.item.approved"
              class="check2-circle text-info mr-1"
              font-scale="1.5"
              @click="approveItem(row.item)"
            />
          </template>
          <template #cell(label)="row">
            <b-form-input
              v-if="row.item.edit"
              v-model="row.item.label"
              :disabled="!row.item.edit"
            />
            <span v-else>
              {{ row.item.label }}
            </span>

          </template>
          <template #cell(type)="row">
            <b-form-select
              v-if="row.item.edit"
              v-model="row.item.type"
              :options="['state', 'fault']"
              size="md"
              class="border border-primary "
              :disabled="!row.item.edit"
            />
            <span v-else>
              {{ row.item.type }}
            </span>

          </template>
          <template #empty="scope">
            <h4>No data found</h4>
          </template>
          <template #emptyfiltered="scope">
            <h4>No data found</h4>
          </template>
        </b-table>
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            :aria-controls="tableID"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BSpinner, BIconTrash, BIconPencilSquare, BIconCheck, BFormInput, BFormSelect, BOverlay, BIconCheck2Circle, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppEchartScatter from '@core/components/charts/echart/AppEchartScatter.vue'
import DatePicker from 'vue2-datepicker'
import assetFactoryStore from '../assetsLogStore'
import AssetHeaderCard from '../components/AssetHeaderCard.vue'
import 'vue2-datepicker/index.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    AssetHeaderCard,
    BTable,
    BSpinner,
    BPagination,
    BIconTrash,
    BIconPencilSquare,
    BFormInput,
    AppEchartScatter,
    BIconCheck,
    BIconCheck2Circle,
    BFormSelect,
    DatePicker,
    BOverlay,
    BFormCheckbox,
    BButton,
  },
  props: {
    threeDimensional: {
      type: Boolean,
      default: false,
    },
    telemetry: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      //   if (timer.value) {
      //     clearInterval(timer.value)
      //     window.clearInterval(timer.value)
      //     clearTimeout(timer.value)
      //   }
    })

    const toast = useToast()
    const isBusy = ref(false)
    const asset = ref({})
    const items = ref([])
    const tableID = 'asset-training-list-table'
    const currentPage = ref(1)
    const perPage = ref(6)
    const rows = ref(0)
    const option = ref(null)
    const selectedOption = ref(null)
    const trainingRequestSelected = ref({})
    const markerData = ref([])
    const quickDraw = ref(false)
    const trainingButton = ref('Submit for training')
    const lag = ref(0)
    const modelName = ref('CNN')
    const fields = [
      {
        key: 'type',
        label: 'Type',
        sortable: true,
        type: 'string',
      },
      {
        key: 'label',
        label: 'Label',
        sortable: true,
        type: 'string',
      },
      {
        key: 'windowStart',
        label: 'Start',
        type: 'number',
        sortable: true,
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'windowEnd',
        label: 'End',
        type: 'number',
        sortable: true,
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'approved',
        label: 'Approval',
        sortable: true,
        type: 'bool',
      },
      {
        key: 'createdBy',
        label: 'Created By',
        sortable: true,
        type: 'string',
      },
      {
        key: 'updatedBy',
        label: 'Updated By',
        sortable: true,
        type: 'string',
      },
      {
        key: 'updatedTime',
        label: 'Updated At',
        sortable: true,
        type: 'number',
        formatter: value => msEpochToDate(value),
      },
      {
        key: 'action', label: 'Action', sortable: false, filterable: false,
      },
    ]
    const currentDate = new Date()
    const firstDay = ref(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    const lastDay = ref(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))

    const fetchThisAssets = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/fetchThisAssets', router.currentRoute.params.assetID)
        .then(response => {
          const { data } = response
          // eslint-disable-next-line prefer-destructuring
          asset.value = data[0]
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    fetchThisAssets()

    const getItems = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/fetchTrainings', { assetID: router.currentRoute.params.assetID, startTime: firstDay.value.getTime(), endTime: lastDay.value.getTime() })
        .then(response => {
          items.value = response.data
          rows.value = response.data.length
          items.value.forEach(item => {
            const area = [
              {
                name: item.label,
                xAxis: item.windowStart,
              },
              {
                xAxis: item.windowEnd,
              },
            ]
            markerData.value.push(area)
          })
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching training data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getItems()

    const handleDelete = item => {
      isBusy.value = true
      store.dispatch('app-asset-log/deleteTraining', item)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'data deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getItems()
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const getGraph = () => {
      option.value = null
      store
        .dispatch('app-asset-log/fetchAssetdominantFreqGraph', {
          assetID: router.currentRoute.params.assetID, limit: 5000, startTime: firstDay.value.getTime(), endTime: lastDay.value.getTime(),
        })
        .then(response => {
          if (response.data) {
            const minX = response.data.minTimeStamp
            const maxX = response.data.maxTimeStamp
            const seriesData = response.data.freqSeries
            const status = response.data.statusSeries
            const { minFreq, maxFreq } = response.data
            const minMag = 0 // response.data.minAmp
            const maxMag = 30 // response.data.maxAmp
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              grid: [
                {
                  left: '2%',
                  right: '2%',
                  height: '50%',
                },
                {
                  left: '2%',
                  right: '2%',
                  top: '75%',
                  bottom: '15%',
                  height: '10%',
                },
              ],
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: false,
                  },
                  saveAsImage: {
                    pixelRatio: 2,
                  },
                },
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                position(pos, size) {
                  const obj = { top: 10 }
                  obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 50
                  return obj
                },
                extraCssText: 'width: 270px',
                formatter(param) {
                  const time = msEpochToDate(param[0].value[0])
                  const value1 = param[0].value
                  const value2 = param[1].value
                  const value3 = param[2].value
                  // prettier-ignore
                  return `
                        <div class="d-flex align-items-center">
                            Date: <div class="font-weight-bold;border:1px solid lightgray;">${time}</div>
                        </div>
                        <table style="text-align:left">
                            <tr>
                                <th>Frequency</th>
                                <th>Magnitude</th>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[2]}</td>
                            </tr>
                        </table>
                    `
                },
              },
              axisPointer: {
                link: { xAxisIndex: 'all' },
                label: {
                  backgroundColor: '#777',
                },
              },
              dataZoom: [
                {
                  realtime: true,
                  type: 'inside',
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
                {
                  type: 'slider',
                  top: '85%',
                  realtime: true,
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
              ],
              xAxis: [
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  name: 'Time',
                  axisLine: {
                    onZero: false,
                  },
                  axisLabel: {
                    formatter(value) {
                      return msEpochToDate(value)
                    },
                  },
                },
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  gridIndex: 1,
                  axisLine: { onZero: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                  axisLabel: { show: false },
                },
              ],
              yAxis: [
                {
                  type: 'value',
                  min: (minFreq - 1).toFixed(2),
                  max: (maxFreq + 1).toFixed(2),
                  scale: false,
                  name: 'Frequency (Hz)',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  splitNumber: 5,
                  interval: 5,
                },
                {
                  type: 'value',
                  gridIndex: 1,
                  min: -1,
                  max: 3,
                  name: 'Status',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  scale: false,
                  splitNumber: 1,
                  interval: 1,
                },
              ],
              visualMap: [
                {
                  top: 50,
                  right: 10,
                  min: minMag,
                  max: maxMag,
                  dimension: 2,
                  seriesIndex: 0,
                  show: false,
                  inRange: {
                    color: ['#28c76f', '#7D3C98', '#F1C40F', '#ff9f43', '#2980B9', '#ea5455'],
                  },
                  calculable: true,
                },
                {
                  top: '60%',
                  right: 10,
                  min: -1,
                  max: 3,
                  dimension: 1,
                  seriesIndex: 1,
                  show: false,
                  inRange: {
                    color: ['grey', '#4b4b4b', '#ff9f43', '#28c76f', '#ea5455'],
                  },
                  calculable: true,
                },
              ],
              series: [
                {
                  symbolSize: 1,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  visualMapIndex: 0,
                  data: seriesData,
                  type: 'scatter',
                  z: 0,
                  large: !quickDraw.value,
                  formatter(param) {
                    const { data } = param[0]
                    return [
                      `Open: ${data[0]}<br/>`,
                      `Close: ${data[1]}<br/>`,
                      `Lowest: ${data[2]}<br/>`,
                      `Highest: ${data[3]}<br/>`,
                    ].join('')
                  },
                  markArea: {
                    silent: true,
                    itemStyle: {
                      color: 'rgba(255, 173, 177, 0.4)',
                    },
                    data: markerData.value,
                  },
                },
                {
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  visualMapIndex: 1,
                  symbolSize: 3,
                  data: status,
                  large: !quickDraw.value,
                  type: 'scatter',
                },
              ],
            }
          } else {
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              title: {
                show: true,
                textStyle: {
                  color: 'grey',
                  fontSize: 20,
                },
                text: 'No Data',
                left: 'center',
                top: 'center',
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
            }
          }

          console.log(option.value)
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    getGraph()

    const getSelectedOption = () => {
      isBusy.value = false
      selectedOption.value = null
      store
        .dispatch('app-asset-log/fetchAssetdominantFreqGraph', {
          assetID: trainingRequestSelected.value.assetID, limit: 5000, startTime: trainingRequestSelected.value.windowStart, endTime: trainingRequestSelected.value.windowEnd,
        })
        .then(response => {
          if (response.data) {
            const minX = response.data.minTimeStamp
            const maxX = response.data.maxTimeStamp
            const seriesData = response.data.freqSeries
            const status = response.data.statusSeries
            const { minFreq, maxFreq } = response.data
            selectedOption.value = {
              brush: {
                toolbox: ['lineX', 'keep', 'clear'],
                xAxisIndex: 0,
              },
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              grid: [
                {
                  left: '2%',
                  right: '2%',
                  height: '50%',
                },
                {
                  left: '2%',
                  right: '2%',
                  top: '75%',
                  bottom: '10%',
                  height: '10%',
                },
              ],
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: false,
                  },
                  saveAsImage: {
                    pixelRatio: 2,
                  },
                },
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                position(pos, params, el, elRect, size) {
                  const obj = { top: 10 }
                  obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 50
                  return obj
                },
                extraCssText: 'width: 270px',
                formatter(param) {
                  const time = msEpochToDate(param[0].value[0])
                  const value1 = param[0].value
                  const value2 = param[1].value
                  const value3 = param[2].value
                  // prettier-ignore
                  return `
                        <div class="d-flex align-items-center">
                            Date: <div class="font-weight-bold;border:1px solid lightgray;">${time}</div>
                        </div>
                        <table style="text-align:left">
                            <tr>
                                <th>Frequency</th>
                                <th>Magnitude</th>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[2]}</td>
                            </tr>
                        </table>
                    `
                },
              },
              axisPointer: {
                link: { xAxisIndex: 'all' },
                label: {
                  backgroundColor: '#777',
                },
              },
              dataZoom: [
                {
                  realtime: true,
                  type: 'inside',
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
                {
                  type: 'slider',
                  top: '85%',
                  realtime: true,
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
              ],
              xAxis: [
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  name: 'Time',
                  axisLine: {
                    onZero: false,
                  },
                  axisLabel: {
                    formatter(value) {
                      return msEpochToDate(value)
                    },
                  },
                },
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  gridIndex: 1,
                  axisLine: { onZero: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                  axisLabel: { show: false },
                },
              ],
              yAxis: [
                {
                  type: 'value',
                  min: (minFreq - 1).toFixed(2),
                  max: (maxFreq + 1).toFixed(2),
                  scale: false,
                  name: 'Frequency (Hz)',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  splitNumber: 5,
                  interval: 5,
                },
                {
                  type: 'value',
                  gridIndex: 1,
                  min: -1,
                  max: 3,
                  name: 'Status',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  scale: false,
                  splitNumber: 1,
                  interval: 1,
                },
              ],
              series: [
                {
                  symbolSize: 3,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  visualMapIndex: 0,
                  data: seriesData,
                  type: 'scatter',
                  formatter(param) {
                    const { data } = param[0]
                    return [
                      `Open: ${data[0]}<br/>`,
                      `Close: ${data[1]}<br/>`,
                      `Lowest: ${data[2]}<br/>`,
                      `Highest: ${data[3]}<br/>`,
                    ].join('')
                  },
                },
                {
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  visualMapIndex: 1,
                  symbolSize: 3,
                  data: status,
                  type: 'scatter',
                },
              ],
            }
          } else {
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              title: {
                show: true,
                textStyle: {
                  color: 'grey',
                  fontSize: 20,
                },
                text: 'No Data',
                left: 'center',
                top: 'center',
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
            }
          }
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const selectItem = row => {
      row.toggleDetails()
      isBusy.value = false
      trainingRequestSelected.value = row.item
      trainingRequestSelected.value.edit = true
      getSelectedOption()
    }
    const handleSave = item => {
      isBusy.value = true
      // eslint-disable-next-line no-param-reassign
      item.approved = false
      // eslint-disable-next-line no-param-reassign
      item.approvedBy = ''
      store
        .dispatch('app-asset-log/updateTraining', item)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Training Request Updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getItems()
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating training request',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const approveItem = item => {
      isBusy.value = true
      // eslint-disable-next-line no-param-reassign
      item.approved = true
      store
        .dispatch('app-asset-log/updateTraining', item)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Training Request Approved',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          getItems()
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating training request',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const brushEnd = (startData, endData) => {
      if (startData && endData) {
        trainingRequestSelected.value.windowStart = Math.round(startData)
        trainingRequestSelected.value.windowEnd = Math.round(endData)
        trainingRequestSelected.value.edit = true
      }
    }

    const queryThisMonth = () => {
      lastDay.value = new Date(firstDay.value.getFullYear(), firstDay.value.getMonth() + 1, 1)
      console.log(firstDay.value, lastDay.value)
      getItems()
      getGraph()
    }

    const submitTraining = () => {
      isBusy.value = true
      trainingButton.value = 'Training...'
      console.log(asset)
      const data = {
        assetID: asset.value.assetID,
        modelName: modelName.value,
        lag: lag.value,
      }
      store
        .dispatch('app-asset-log/submitForTraining', data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Training Request Submitted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error submitting training request',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
          trainingButton.value = 'Submit For Training'
        })
    }

    return {
      getItems,
      isBusy,
      asset,
      items,
      currentPage,
      perPage,
      rows,
      tableID,
      fields,
      handleDelete,
      brushEnd,
      getGraph,
      option,
      handleSave,
      firstDay,
      lastDay,
      queryThisMonth,
      selectItem,
      selectedOption,
      approveItem,
      quickDraw,
      trainingButton,
      submitTraining,
      lag,
      modelName,
    }
  },
}
</script>
